import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import theme from 'theme';

/* For any content that needs to keep max-width 1115px, e.g. Card lists */
export const ContentArea = styled.div`
  ${theme.breakpoints.down('lg')} {
    max-width: 100%;
    justify-content: flex-start;
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1115px;
  gap: ${theme.spacing(3)};
  margin: auto;
`;

/* For card lists that are scrollable sideways on mobile */
export const CardContainer = styled.div`
  ${theme.breakpoints.down('lg')} {
    gap: ${theme.spacing(2)};
    flex-wrap: nowrap;
    padding-right: ${theme.spacing(2)};
    padding-left: ${theme.spacing(2)};
  }
  display: flex;
  justify-content: flex-start;
  gap: ${theme.spacing(3)};
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
`;

/* Featured Blog post */
export const Hero = styled.div`
  ${theme.breakpoints.down('sm')}{
    height: auto;
    flex-direction: column;
    margin-bottom: ${theme.spacing(2)};
  }
  width: 100%;
  height: ${theme.spacing(48)};
  display: flex;
  flex-direction: row;
  margin-bottom: ${theme.spacing(6)};
`;

/* Featured Blog post */
export const HeroImage = styled.img`
  ${theme.breakpoints.down('sm')}{
    width: 100%;
    height: 210px;
  }
  width: 65%;
  height: 480px;
  object-fit: cover;
`;

/* Featured Blog post */
export const HeroContent = styled.div`
  ${theme.breakpoints.down('sm')}{
    width: 100%;
    height: auto;
    padding: ${theme.spacing(3, 0)};
  }
  width: 35%;
  height: 480px;
  display: flex;
  padding: ${theme.spacing(4.5)};
  align-items: center;
`;

/* Select / Dropdown */
export const StyledSelect = styled(Select)`
  padding: 0px 30px 0px 10px;
  &>.MuiSelect-icon {
    color: #1e1e1e;
  }
`;

/* MenuItem for Select / Dropdown */
export const StyledMenuItem = styled(MenuItem)`
  background-color: #fff;
    :hover {
      background-color: #E5361F;
      color: #fff;
    }
    &.Mui-selected {
        background-color: #F8F8F8;
        :hover {
          background-color: #E5361F;
          color: #fff;
        }
    }
  `;

/* Small badge for informing that a choice was valid e.g. address or extra service */
export const ValidBadge = styled(Badge)((
) => `
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #F1F1F2;
  color: #85C71A;
  justify-content: center;
  align-items: center;
  .MuiSvgIcon-root {
    font-size: 16px;
  }
  `);

/* Service card & Extra service card */
export const CardContentContainer = styled.div(
  ({
    theme,
  }) => `
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${theme.spacing(2)};
  background-color: #ffffff;
`);

/* Service card & Extra service card */
export const CardSpecial = styled.div`
  width: fit-content;
  font-size: 0.75rem;
  background-color: #E5361F;
  color: #fff;
  border-radius: 4px;
  position: absolute; 
  text-transform: uppercase;
  top: 1.25rem;
  left: 1.25rem;
  padding: 0.313rem;
`;

export const DiscountSpecial = styled(CardSpecial)`
  left: 5.8rem;
`;

export const DiscountPrice = styled.div(({
  theme,
}) => `
    display: inline-flex;
    color: ${theme.palette.secondary.main};
    padding-left: 2px;
  `);

/* List style for Blog cards */
export const BlogCardItem = styled.li`
list-style: none;
`;

/* Title style for Sanity content for general pages */
export const Title = styled(Typography)`
${theme.breakpoints.down('sm')}{
  font-size: 1.625rem;
}
font-size: 2rem;
font-weight: 500;
line-height: 2.375rem;
letter-spacing: -0.16px;
`;

/* Subtitle style for Sanity content for general pages */
export const Subtitle = styled(Typography)`
${theme.breakpoints.down('sm')}{
  font-size: 1rem;
  line-height: 1.5rem;
}
font-size: 1.25rem;
font-weight: 500;
line-height: 1.625rem;
letter-spacing: 0.1px;
`;

/* Text styles for Portable text component (Sanity content) for general pages */
/* TODO: clean up the styling of text elements */
export const TextContainer = styled.div`
  ${theme.breakpoints.down('lg')}{
    width: 100%;
    margin: 0px;
  }
  width: 810px;
  margin: 100px auto; 
  padding: ${theme.spacing(3, 0)};
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing(3)};
  /*  h1 */
  &> div > h1 {
    ${theme.breakpoints.down('sm')}{
      font-size: 1rem;
      line-height: 1.5rem;
    }
    font-size: 1.35rem;
    font-weight: 500;
    line-height: 1.625rem;
    letter-spacing: 0.1px;
    margin-top: ${theme.spacing(4)};
  }
  /*  h2 */
  &> div > h2 {
    ${theme.breakpoints.down('sm')}{
      font-size: 1rem;
      line-height: 1.5rem;
    }
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.625rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  /* h3 */
  &> div > h3 {
    ${theme.breakpoints.down('sm')}{
      font-size: 1rem;
      line-height: 1.5rem;
    }
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.625rem;
    letter-spacing: 0.1px;
  }
  /* p */
  &> div > p { 
    ${theme.breakpoints.down('sm')}{
      font-size: 1rem;
      line-height: 1.25rem;
      letter-spacing: 0px;
    }
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0.09px;
  }
  /* a */
  &> div > p > a { 
    ${theme.breakpoints.down('sm')}{
      font-size: 1rem;
      line-height: 1.25rem;
      letter-spacing: 0px;
    }
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0.09px;
    text-decoration: underline;
  }
`;

/* Order Progress Flow content container */
export const OrderContentContainer = styled.div`
  ${theme.breakpoints.down('md')} {
    margin-right: 0;
  }
  max-width: 100%;
  display: flex;
  row-gap: ${theme.spacing(2)};
  align-items: flex-start;
  flex-direction: column;
  margin-right: ${theme.spacing(4)};
`;
