import { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import React, { ForwardRefRenderFunction } from 'react';
import styled from '@emotion/styled';
import theme from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const Content = styled.div<{
  $isMobile,
  $mobileWithPadding?
  $paddingTop?
  $paddingBottom?
}>(({
  theme,
  $isMobile,
  $mobileWithPadding,
  $paddingTop,
  $paddingBottom,
}) => css`
  display: flex;
  position: relative;
  height: auto;
  flex: 1 0 auto;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: auto;
  padding-left: ${theme.spacing($isMobile && !$mobileWithPadding ? 0 :
  $isMobile && $mobileWithPadding ? 2 : 16.5)};
  padding-right: ${theme.spacing($isMobile && !$mobileWithPadding ? 0 :
  $isMobile && $mobileWithPadding ? 2 : 16.5)};
  padding-top: ${theme.spacing($paddingTop ? 6 : 0)};
  padding-bottom: ${theme.spacing($paddingBottom ? 7 : 0)};
`);

interface ContentContainerProps {
  style?: React.CSSProperties;
  mobileWithPadding?: boolean;
  paddingTop?: boolean;
  paddingBottom?: boolean;
}

const Container: ForwardRefRenderFunction<HTMLDivElement, PropsWithChildren<ContentContainerProps>> = (
  {
    children,
    style,
    mobileWithPadding,
    paddingTop,
    paddingBottom,
  }: PropsWithChildren<ContentContainerProps>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return <Content
    ref={ref}
    $mobileWithPadding={mobileWithPadding}
    $paddingTop={paddingTop}
    $paddingBottom={paddingBottom}
    $isMobile={isMobile}
    id="content-container"
    style={style}
    data-test-id="content-container">
    { children }
  </Content>
  ;
};

const ContentContainer = React.forwardRef(
  Container
);

ContentContainer.displayName = 'ContentContainer';

export default ContentContainer;
